import React from "react";
import { PROXY_URL } from "../../shared/constants";
import "./Footer.css";
import logoFull from "../../img/optimized/commoin/logo-full-black.webp";
import vk from "../../img/footerIcons/vk.png";
import telegram from "../../img/footerIcons/telegram.svg";
import youtube from "../../img/footerIcons/youtube.svg";
import phone from "../../img/footerIcons/phone.png";
import mail from "../../img/footerIcons/mail.png";
import Cookies from 'universal-cookie';
import { isBetweenDateTimes } from '../../shared/utils';

function Footer() {
  const tgGTM = () => {
    dataLayer.push({'event': 'tg'})
  }
  const vkGTM = () => {
    dataLayer.push({'event': 'vk'})
  }
  const youtubeGTM = () => {
    dataLayer.push({'event': 'youtube'})
  }

  const cookies = new Cookies();
  const enV = cookies.get('googtrans') || '';
  const isEnVersion = enV.includes('en');
  const showNewOfferDoc = isBetweenDateTimes('2024.07.15', '2024.07.23');

  return (
    <div className="footer">
      <a href={PROXY_URL}>
        <img className="logo-full-black" src={logoFull} alt="logo full" />
      </a>
      <div className="frame-178 inter-normal-manatee-14px">
        <p className="text-1-45">
          <span>
            ООО "М-БК"
            <br />
            ИНН 9702055857 | ОГРН 1237700294974
            <br />
            129110, город Москва, пр-кт Мира, д. 79, кв. 65
          </span>
        </p>
        <div className="copyright">© 2018-2024</div>
      </div>
      <div className="frame-176">
        <div className="frame-73">
          {!isEnVersion && (
            <a
              className="text-15 inter-normal-manatee-14px"
              href={PROXY_URL + "/upload/public_offer_rf.pdf"}
            >
              Оферта
            </a>
          )}
          {isEnVersion && (
            <a
              className="text-15 inter-normal-manatee-14px"
              href={PROXY_URL + "/upload/public_offer_en.pdf"}
            >
              Оферта
            </a>
          )}
        </div>
        <div className="frame-72">
          <a
            className="text-15 inter-normal-manatee-14px"
            href={PROXY_URL + "/terms-conditions/"}
          >
            Условия и политика конфиденциальности
          </a>
        </div>
        <div className="frame-71">
          {!isEnVersion && (
            <a
              className="text-15 inter-normal-manatee-14px"
              href={PROXY_URL + "/upload/cert_public_offer.pdf"}
            >
              Правила предоставления электронных подарочных сертификатов и
              промокодов
            </a>
          )}
          {isEnVersion && (
            <a
              className="text-15 inter-normal-manatee-14px"
              href={PROXY_URL + "/upload/public_offer_en_cert.pdf"}
            >
              Правила предоставления электронных подарочных сертификатов и
              промокодов
            </a>
          )}
        </div>
        <div className="frame-71">
          {!isEnVersion && (
            <a
              className="text-15 inter-normal-manatee-14px"
              href={PROXY_URL + "/upload/raffle_public_offer.pdf"}
            >
              Правила проведения розыгрыша
            </a>
          )}
          {isEnVersion && (
            <a
              className="text-15 inter-normal-manatee-14px"
              href={PROXY_URL + "/upload/raffle_public_offer.pdf"}
            >
              Правила проведения розыгрыша
            </a>
          )}
        </div>
      </div>
      <div className="frame-177">
        <div className="frame-74">
          <img className="mail" src={mail} alt="mail" />
          <div className="supportbeautyclub365ru inter-normal-manatee-14px">
            <a
              style={{ textDecoration: "none", color: "unset" }}
              href="mailto:support@beautyclub365.ru"
            >
              support@beautyclub365.ru
            </a>
          </div>
        </div>
        <div className="frame-175">
          <img className="phone-call" src={phone} alt="phone-call" />
          <div className="phone inter-normal-manatee-14px">
            <a
              style={{ textDecoration: "none", color: "unset" }}
              href="tel:+79257336443"
            >
              8 (925) 733-64-43
            </a>
          </div>
        </div>
        <div className="footer-social-media-icons">
          <a href="https://t.me/beautyclub365" onClick={tgGTM}>
            <img
              className="footer-social-media-icon"
              src={telegram}
              alt="telegram"
            />
          </a>
          <a href="https://vk.com/beautyclub365" onClick={vkGTM}>
            <img className="footer-social-media-icon" src={vk} alt="vk" />
          </a>
          <a href="https://www.youtube.com/@beautyclub365/featured" onClick={youtubeGTM}>
            <img
              className="footer-social-media-icon"
              src={youtube}
              alt="youtube"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
